import {Component, ViewContainerRef} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {InfoModalComponent} from './info-modal/info-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pizzeriasalamandra';

  constructor(
    private readonly ngxSmartModalService: NgxSmartModalService,
    private readonly vcr: ViewContainerRef
  ) {
    const modal = this.ngxSmartModalService.create('infoModal', InfoModalComponent);
    modal.open();
  }

}
