

<carousel [noPause]="false" [pauseOnFocus]="true">
  <slide>
    <div class="text-center py-5 bg-dark text-white">
      <h2>First custom content</h2>
      <div class="lead">
        <h3>First slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </div>
    </div>
  </slide>
  <slide>
    <div class="text-center py-5 bg-dark text-white">
      <h2>Second custom content</h2>
      <div class="lead">
        <h3>Second slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </div>
    </div>
  </slide>
  <slide>
    <div class="text-center py-5 bg-dark text-white">
      <h2>Third custom content</h2>
      <div class="lead">
        <h3>Third slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum. </p>
      </div>
    </div>
  </slide>
</carousel>
