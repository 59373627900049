import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  lat = new Date().getFullYear() - 2004;

  constructor(
    private title: Title
  ) {

  }

  ngOnInit() {
    this.title.setTitle(`Pizza, Kebab, Makarony, Sałatki | Pizzeria Salamandra | Skórzewo ul. Poznańska 80`);
  }

}
