import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { share, } from 'rxjs/operators';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent  {

  activeFragment = this.route.fragment;

  collapseMenu;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
      console.log(this.isVisible('promocje'));
  }

  constructor(
    public route: ActivatedRoute,
    private router: Router
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        this.collapseMenu = false;
      }
    });
  }

  isVisible(id) {
    const el = document.getElementById(id);
    if (el !== null) {
      const bounding = el.getBoundingClientRect();
      // console.log(bounding.top - window.innerHeight, bounding.bottom);
      return (
        (bounding.top - window.innerHeight) < 0 &&
        bounding.bottom > 0
      );
    } else {
      return false;
    }

}

}
