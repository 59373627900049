<p class="lead">Drodzy Klienci!</p>
<p>
  Informujemy, że od dnia 20.11 do 20.12 dostawa do Dąbrówki nie będzie możliwa ze względu na utrudnienia w ruchu drogowym.
</p>
<p>
  Dodatkowo dostawa do Palędzia, Gołusek i Dopiewca możliwa będzie w godzinach wieczornych: 18-21:30.
</p>
<p>
  <strong>
    Zachęcamy do odbioru osobistego w lokalu.
  </strong>
</p>
