<nav class="navbar navbar-expand-sm">
  <div class="container">
    <a class="navbar-brand" routerLink="/">
      <img src="/assets/images/small-logo.png" alt="Pizzeria Salamandra - Skórzewo">
    </a>
    <button class="navbar-toggler" type="button" (click)="collapseMenu = !collapseMenu">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" role="img" focusable="false">
        <title>Menu</title>
        <path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"
          d="M4 7h22M4 15h22M4 23h22"></path>
      </svg>
    </button>

    <div class="collapse navbar-collapse" [ngClass]="{'show': collapseMenu}" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
<!--        <li class="nav-item dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">-->
<!--          <a class="nav-link"  >-->
<!--            menu <span class="caret"></span>-->
<!--          </a>-->
<!--          <div class="dropdown-menu">-->
<!--            <div class="container">-->
<!--              <ul role="menu">-->

<!--                <li routerLinkActive="active">-->
<!--                  <a routerLink="/menu/pizza"-->
<!--                    style="background-image: url('/assets/uploads/cache/kategoria_thumb/kategoria/pizza.jpg'); ">-->
<!--                    <span>Pizza</span>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li routerLinkActive="active">-->
<!--                  <a routerLink="/menu/kebab"-->
<!--                    style="background-image: url('/assets/uploads/cache/kategoria_thumb/kategoria/kebab.jpg'); ">-->
<!--                    <span>Kebab</span>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li routerLinkActive="active">-->
<!--                  <a routerLink="/menu/makarony"-->
<!--                    style="background-image: url('/assets/uploads/cache/kategoria_thumb/kategoria/makarony.jpg'); ">-->
<!--                    <span>Makarony</span>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li routerLinkActive="active">-->
<!--                  <a routerLink="/menu/salatki"-->
<!--                    style="background-image: url('/assets/uploads/cache/kategoria_thumb/kategoria/salatki.jpg'); ">-->
<!--                    <span>Sałatki</span>-->
<!--                  </a>-->
<!--                </li>-->
<!--                <li routerLinkActive="active">-->
<!--                  <a routerLink="/menu/napoje"-->
<!--                    style="background-image: url('/assets/uploads/cache/kategoria_thumb/kategoria/napoje.jpg'); ">-->
<!--                    <span>Napoje</span>-->
<!--                  </a>-->
<!--                </li>-->

<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--        </li>-->
        <li class="nav-item" [class.active]="isVisible('menu')">
          <a class="nav-link" routerLink="/" fragment="menu">menu</a>
        </li>
<!--        <li class="nav-item" [class.active]="isVisible('promocje')">-->
<!--          <a class="nav-link" routerLink="/" fragment="promocje">promocje</a>-->
<!--        </li>-->
        <li class="nav-item" [class.active]="isVisible('dowoz')">
          <a class="nav-link" routerLink="/" fragment="dowoz">dowóz</a>
        </li>
        <li class="nav-item" [class.active]="isVisible('kontakt')">
          <a class="nav-link" routerLink="/" fragment="kontakt">kontakt</a>
        </li>
      </ul>

    </div>
  </div>
</nav>

<!-- <nav class="navbar navbar-black navbar-fixed-top">
  <div class="container">
      <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#headerMenu">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" routerLink="/">
              <img src="/assets/images/small-logo.png" alt="Pizzeria Salamandra - Skórzewo">
          </a>
      </div>
      <div class="collapse navbar-collapse" id="headerMenu">
          <ul class="nav navbar-nav">

              <li [class.active]="isSectionActive('promocje')">
                <a routerLink="/" fragment="promocje">promocje</a>
              </li>
              <li [class.active]="isSectionActive('dowoz')">
                <a routerLink="/" fragment="dowoz">dowóz</a>
              </li>
              <li [class.active]="isSectionActive('kontakt')">
                <a routerLink="/" fragment="kontakt">kontakt</a>
              </li>
          </ul>
      </div>
  </div>
</nav> -->
