import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { MenuComponent } from './menu/menu.component';

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 66]
};

const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: '*',
    redirectTo: '/',
    pathMatch: 'full'
  }
  // {
  //   path: 'menu-pizza',
  //   redirectTo: '/menu/pizza'
  // },
  // {
  //   path: 'menu-kebab',
  //   redirectTo: '/menu/kebab'
  // },
  // {
  //   path: 'menu-makarony',
  //   redirectTo: '/menu/makarony'
  // },
  // {
  //   path: 'menu-salatki',
  //   redirectTo: '/menu/salatki'
  // },
  // {
  //   path: 'menu-napoje',
  //   redirectTo: '/menu/napoje'
  // },
  // {
  //   path: 'menu',
  //   children: [
  //     {
  //       path: ':slug',
  //       component: MenuComponent
  //     }
  //   ]
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
