import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {tap} from 'rxjs/operators';
import {KeyValue} from '@angular/common';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  menu$;
  // currentItem;
  menu;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    // this.currentItem = activatedRoute.snapshot.params.slug;
    this.menu$ = this.http.get('/assets/menu.json?v=03082022', {responseType: 'json'})
      .pipe(tap(val => {

        // this.title.setTitle(`${val[this.currentItem].name} menu | Pizzeria Salamandra | Skórzewo ul. Poznańska 80`);
        // this.router.events.subscribe((event) => {
        //   if (event instanceof NavigationEnd) {
        //     this.currentItem = activatedRoute.snapshot.params.slug;
        //     this.title.setTitle(`${val[this.currentItem].name} menu | Pizzeria Salamandra | Skórzewo ul. Poznańska 80`);
        //   }
        // });

      })).subscribe(res => {
        this.menu = res;
      });


  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  ngOnInit() {
  }

}
