<section id="menu" class="full" *ngIf="menu">

<!--  <pre><code>{{ menu | json }}</code></pre>-->

  <ng-container *ngFor="let prod of menu | keyvalue : originalOrder">

    <ng-container *ngIf="prod.key !== 'alergeny'">

<!--      <div class="image" [ngStyle]="{'background-image': 'url(/assets/uploads/cache/slider_lg/kategoria/'+ item.key +'.jpg)'}"></div>-->
      <div class="name_bar">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-auto">
            <h2 class="m-0">
              {{ menu[prod.key].name }}
            </h2>
          </div>
          <div class="col-auto">
            <button class="btn" (click)="menu[prod.key].showAlergeny = !menu[prod.key].showAlergeny" *ngIf="menu[prod.key]?.data?.[0]?.price.length === 3">
              {{ menu[prod.key].showAlergeny ? 'ukryj' : 'pokaż' }} alergeny
            </button>
          </div>
        </div>

      </div>
      </div>
      <div class="container menu">
        <table class="table">
          <thead>
            <tr>
              <th width="1%"></th>
              <th class="ingredients"></th>
              <th></th>
              <ng-container *ngIf="menu[prod.key]?.data?.[0]?.price.length === 3">
                <th class="text-center">24 cm</th>
                <th class="text-center">31 cm</th>
                <th class="text-center">42 cm</th>
              </ng-container>
              <ng-container *ngIf="menu[prod.key]?.data?.[0]?.price.length === 2">
                <th class="text-center">mały</th>
                <th class="text-center">duży</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of menu[prod.key].data; let index = index;">
              <tr>
                <td>
                  {{ index + 1 }}.
                </td>
                <td>
                  <strong>{{ item.name | uppercase }}</strong>
                </td>
                <td class="ingredients">
                  {{ item.ingredients }}

<!--                  <pre><code>{{ menu[prod.key]?.showAlergeny }}</code></pre>-->

                  <ng-container *ngIf="menu[prod.key]?.showAlergeny">
                    <br>
                    <small style="font-size: 11px">
                      <ng-container *ngFor="let itm of item?.alergeny; let last = last;">{{ menu['alergeny'][itm] }}{{ last ? '' : ', ' }}</ng-container>
                    </small>
                  </ng-container>

                </td>
                <ng-container *ngFor="let price of item.price; let index = index;">
                  <td class="text-center" *ngIf="price" [attr.colspan]="item.price[index - 1] === null ? 2 : null">
                    {{ (menu[prod.key]?.data?.[0]?.price?.length === 3 ? price : price) | number:'1.2-2' }} zł
                  </td>
                </ng-container>
              </tr>
              <tr class="ingredients-mobile">
                <td [attr.colspan]="item.price.length + 2">{{ item.ingredients }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>
  </ng-container>


</section>
