<div class="container py-5">
  <div class="row">
    <div class="col-12">
      <img src="./../../assets/images/logo.png" alt="Pizzeria Salamandra">
    </div>
  </div>
  <div class="row py-4">
    <div class="col-12 col-md-4 ">
      <p><strong>Adres</strong></p>
      <p>ul. Poznańska 80<br>60-185 Skórzewo</p>
    </div>
    <div class="col-12 col-md-4 ">
      <p><strong>Telefon</strong></p>
      <p>tel: +48 61 894 64 85<br>
        kom: +48 605 850 795</p>
    </div>
    <div class="col-12 col-md-4">
      <p><strong>Godziny otwarcia</strong></p>
      <p>od poniedziałku do niedzieli: 12:00 - 22:00<br>telefon odbieramy do godz. 21:30</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p class="copy">
        &copy; 2020 - {{ now | date : 'yyyy' }} Pizzeria Salamandra. <br>Realizacja <a href="https://rafal.org" rel="noopener" target="_blank">&lt;rafal.org /&gt;</a>
      </p>

      <p style="position: relative; top: 30px">
        Niniejszym oświadczamy, że Pizzeria Salamandra otrzymała pomoc finansową od Polskiego Funduszu Rozwoju PFR 2.0, dzięki czemu miała środki na dalsze funkcjonowanie firmy.
      </p>
    </div>
  </div>
</div>
